import { navigate } from "gatsby-link";
import { Box, Text } from "grommet";
import React from "react";
import service from '../../services/service';
import PrimaryButton from "../elements/button";

const Header = ({ data, size }) => {
    console.log('is size: ', size);
    return (
        <Box width="xlarge">
            <Box style={{ height: 15 }} />
            <Box direction='row' justify="between" gap="small">
                <Text style={{ fontWeight: 'bold' }} size={size === 'small' ? 'medium' : "xxlarge"}>Be Perfect Manucure</Text>
                <PrimaryButton
                    label="Prendre rendez-vous"
                    onClick={() => {
                        const source = new URLSearchParams(window.location.search).get('s');
                        const userId = localStorage.getItem('userId');
                        service.tracking({ source, userId, type: 'Click on rdv button' });
                        navigate('https://widget.treatwell.fr/salon/418312/menu/');
                    }}
                />
            </Box>
            <Box style={{ height: 15 }} />
            <Box direction="row" gap="small" align="center">
                <span style={{ fontWeight: 'bold', fontSize: getFontSize(size), color: '#ffb500' }}>{data?.rating?.displayAverage}</span>
                <Box style={{ marginTop: -3 }} direction="row" gap="xsmall">
                    <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                </Box>
                <Text size="medium" style={{ cursor: 'pointer', color: '#001e62', fontWeight: 'bold' }}>{data?.rating?.count} avis</Text>
            </Box>
        </Box>
    )
}

const getIconSize = size => {
    if (size === 'small') return 18;
    return '24';
}

const getFontSize = size => {
    if (size === 'small') return 20;
    return 28;
}

export default Header;