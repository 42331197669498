const axios = require('axios');
const config = require('../config/config.json');

const getSalon = () => {
    if (typeof window !== undefined) {
        let st = localStorage.getItem('salon');
        st = JSON.parse(st);
        if (st && st.updatedAt && st.updatedAt > (new Date().getTime() - 24 * 3600 * 1000)) {
            st.salon.shouldNotCache = true;
            return Promise.resolve(st.salon);
        }
    }
    return axios.get(`${config.baseAPI}/salon/current`);
}

const getPosts = () => {
    if (typeof window !== undefined) {
        let st = window.localStorage.getItem('insta_posts');
        st = JSON.parse(st);
        if (st && st.updatedAt && st.updatedAt > (new Date().getTime() - 24 * 3600 * 1000)) {
            st.posts.shouldNotCache = true;
            return Promise.resolve(st.posts);
        }
    }
    return axios.get(`${config.baseAPI}/salon/posts`, { params: { _sort: 'createdAt:DESC' } });
}


const sendFeedback = (feedback) => {
    if (feedback.id) {
        return axios.put(`${config.baseAPI}/feedback/${feedback.id}`, feedback);
    }
    return axios.post(`${config.baseAPI}/feedback`, feedback);
}

const findRef = ref => {
    return axios.get(`${config.baseAPI}/tw/refs`, { params: { ref } });
}

function hash(value) {
    var hash = 0, i, chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

const updateClient = client => {
    return axios.put(`${config.baseAPI}/public/client/${client.id}`, client);
}

const searchClients = (page = 0, query = '') => {
    return axios.get(`${config.baseAPI}/public/search/client`, { params: { page, query } });
}

const payer = body => {
    return axios.post(`${config.baseAPI}/public/payment`, body);
}

const getSendingReview = () => {
    return axios.get(`${config.baseAPI}/public/feedback`);
}

const updateVisited = ref => {
    return axios.put(`${config.baseAPI}/public/feedback/visite`, {ref});
}
const tracking = (body) => {
    return axios.post(`${config.baseAPI}/public/tracking/reservations`, body);
}

module.exports = {
    getSalon,
    getPosts,
    sendFeedback,
    findRef,
    hash,
    searchClients,
    updateClient,
    payer,
    getSendingReview,
    updateVisited,
    tracking
}