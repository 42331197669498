import { Avatar, Box, Image, Text } from 'grommet';
import { Chat, Facebook, Instagram, Location, Mail, Phone } from 'grommet-icons';
import React from 'react';
import messenger from '../../../images/mess.png'
import treatwell from '../../../images/treatwell.png'

const Footer = ({size}) => {

    const [open, setOpen] = React.useState(false);

    const openSendMail = () => {
        setOpen(true);
    }
    const getFontSize = size => {
        if(size === 'small') return 'xsmall';
        return 'small';
    }

    return (
        <Box width='xlarge'>
            <Box width='xlarge' direction='row' justify='around' style={{paddingTop: 15, paddingBottom: 15}} gap="medium">
                <Box gap="medium" justify="center">
                    <Box direction="row" gap="xsmall" align="center">
                        <Location />
                        <Text size={getFontSize(size)}>15 Avenue du Général de Gaulle,<br />94160, St Mandé</Text>
                    </Box>
                    <a href="tel:+33953640307" style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap='small'>
                            <Phone />
                            <Text size={getFontSize(size)} color='#444444'>09 53 64 03 07</Text>
                        </Box>
                    </a>
                    <a href="mailTo:contact@beperfect.fr" style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap='small'>
                            <Mail />
                            <Text size={getFontSize(size)} color='#444444'>contact@beperfect.fr</Text>
                        </Box>
                    </a>
                    <a href="https://m.me/beperfectmanucure" style={{ textDecoration: 'none' }}>
                        <Box direction='row' gap='small'>
                            <Chat/>
                            <Text size={getFontSize(size)} color='#444444'>Envoie une message</Text>
                        </Box>
                    </a>
                </Box>
                <Box gap="small">
                    <Box direction="row">
                        <Text>À Propos</Text>
                    </Box>
                    <Text size={getFontSize(size)}>
                        Be Perfect Mancure propose tous les services 
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer;