import { Button } from "grommet";
import React from "react";

const buttonStyle = { maxHeight: 80, borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, border: 'none' };
const PrimaryButton = ({ icon, onClick, label, background, color, disabled, reverse = false, size="small", ...rest }) => {

    let style = background ? Object.assign({ background }, buttonStyle) : buttonStyle;
    style = color ? Object.assign({ color }, style) : style;

    return <Button
        disabled={disabled}
        icon={icon} 
        primary 
        reverse={reverse}
        style={style} 
        onClick={onClick} 
        label={label}
        size={size}
        {...rest}
    />
}

export default PrimaryButton;