import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "./layout.css";
import {
  Grommet,
  Box,
  ThemeContext,
  ResponsiveContext,
  Footer,
  Text,
} from "grommet";
import { grommet } from "grommet/themes";

import CustomFooter from '../elements/footer';
import SEO from "../seo";

const Layout = ({ title, description, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          image
        }
      }
    }
  `);
    console.log(data);

  return (
    <Grommet
      theme={{...grommet}}
      style={{
        display: "flex",
        fontFamily: 'beperfect',
        flexDirection: "column",
      }}
    >
      <SEO title={"Be Perfect Manucure | Sentez la perfection"} 
      description={data?.site?.siteMetadata?.description} 
      />
      <ThemeContext.Extend
        value={{
          global: { colors: { brand: "#E92C70" } },
          rangeInput: {
            track: {
              height: "10px",
              lower: {
                color: "#59A4F4",
                opacity: 0.7,
              },
              upper: {
                color: "dark-4",
                opacity: 0.3,
              },
            },
            thumb: {
              color: "#fff",
            },
          },
        }}
      >
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box full="vertical" style={{height: '100%', minHeight: '100vh'}}>
              {children}
              <Footer
                  background="#e8e8e8"
                  justify="center"
                  pad="small"
                  style={{ bottom: 0, width: '100%', color: '#444444' }}
                >
                  <CustomFooter size={size}/>
                </Footer>
            </Box>
          )}

        </ResponsiveContext.Consumer>
      </ThemeContext.Extend>
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
